// Dependency
import React, { useEffect } from "react";

// Components
import { useCart } from "../../contexts/cart-context";

// Template
export default function CheckoutCart ({ setCart }) {

    const { cart } = useCart();

    useEffect(() => {
        if ( cart.selection.items.length === 0 ) {
            setCart(cart.selection.items)
        }
    }, [cart.selection.items])

    return (
        <>
        </>
    )
}
