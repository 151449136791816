//Dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';

//Components
import { useCart } from "../../../contexts/cart-context";
import Storage from "../../../services/localStorage.service";
import UtilsService from "../../../services/utils.service";

// Styles
import * as ingridStyles from "./ingrid.module.scss"
import * as adyenStyles from '../adyen/adyen.module.scss'


// Assets


// Internationalization
import { useTranslation } from 'react-i18next';
import * as styles from "../../mini-cart/cart-footer/cart-footer.module.scss";

const Ingrid = (props) => {
    const { cart, setCart } = useCart();

    const countries = cart.countries ? cart.countries.filter(c => c.country !== 'SE' && c.country !== 'DK' && c.country !== 'NO' && c.country !== 'FR' && c.country !== 'NL').map(country => ({ ...country, label: country.name, value: country.country })) : [];

    const [selectedOption, setSelectedOption] = useState(
        countries.find((item) => item.value === cart.location.country)
    );

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '50px',
        }),
    };

    const updateCountry = async (c) => {
        let address = {
            "firstName": "",
            "lastName": "",
            "email": "",
            "phoneNumber": "",
            "address1": "",
            "address2": "",
            "zipCode": null,
            "city": "",
            "country": c,
        }
        let shippingAddress = address

        axios.defaults.headers.put['Accept'] = `*/*;api-token: ${cart.token}`;

        await axios({
            method: 'PUT',
            url: `${process.env.CENTRA_URL}/payment-fields`,
            data: { address, shippingAddress }
        }).then(response => {
            setCart(response.data);
        }).catch((error) => {
            console.log(error)
            let text = '';
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                text += `${key} is ${value}`;
            }
            //toggleLoading(false);

        });
    }

    const handleCountryChange = async (v) => {
        console.log(v)
        setSelectedOption(v);
        await updateCountry(v.value);
        window.location.reload();

    }


    useEffect(() => {
        const shippingContainer = document.getElementById('shippingContainer'); // Reference to element already on page
        shippingContainer.innerHTML = Storage.getWithExpiry('selection').selection.pluginFields.shipwallet.snippet;
        //evaluate(checkoutContainer);
        UtilsService.evaluate(shippingContainer);
    }, [])

    //Add Centra Checkout Script (Maybe move to checkout or other component)
    useEffect(() => {

        const code = Storage.getWithExpiry('selection').selection.centraCheckoutScript;
        const centraScript = document.createElement('script');

        centraScript.innerHTML = code;

        document.body.appendChild(centraScript);

        return () => {
            document.body.removeChild(centraScript);
        }
    }, [])

    useEffect(() => {
        const handleData = (origdata) => {
            const url = `${process.env.CENTRA_URL}/payment-fields`
            axios.defaults.headers.put['Accept'] = `*/*;api-token: ${Storage.getWithExpiry('selection').token}`;
            axios({
                method: 'put',
                url: url,
                data: origdata.detail
            }).then(response => {
                setCart(response.data);
                window.totalPrice = response.data.selection.totals.grandTotalPriceAsNumber
                window.CentraCheckout.resume(origdata.detail.additionalFields.suspendIgnore);
            }).catch((error) => {
                console.log(error)
            });
        }
        document.addEventListener('centra_checkout_callback', handleData);

        return () => {
            document.removeEventListener('centra_checkout_callback', handleData)
        }
    }, [])

    // Translation Function
    const { t } = useTranslation();

    return (
        <div>
            {props.country === 'eu' &&
                <div className={ingridStyles.cdIngridLabel}>
                    <h5>{t('langSwitch.changeCountry')}</h5>
                    {/* <div className={ingridStyles.cdMaintenanceText}>
                    <span className="material-icons">info</span>
                    <p>{t('checkout.maintenance')}</p>
                </div> */}
                    <div className={adyenStyles.cdAdyenSelects}>
                        <Select
                            id="country"
                            name="country"
                            className={adyenStyles.cdSelect}
                            defaultValue={selectedOption}
                            options={countries}
                            styles={customStyles}
                            onChange={(v) => handleCountryChange(v)}
                        />
                    </div>
                </div>}
            <div className={ingridStyles.cdIngridLabel}>
                <h5>{t('checkout.delivery')}</h5>
                {/* <div className={ingridStyles.cdMaintenanceText}>
                    <span className="material-icons">info</span>
                    <p>{t('checkout.maintenance')}</p>
                </div> */}
            </div>
            <div className={ingridStyles.cdDeliverWrapper} id="shippingContainer"></div>
        </div>
    )
}

export default Ingrid