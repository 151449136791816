// Dependency
import React from "react";
import { Link } from "gatsby";

// Components

// Services
import UtilsService from "../../../services/utils.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Assets
import breakImg from '../../../images/404.svg';


const CartEmpty = ({pageContext}) => {
    // Translation Function
    const { t } = useTranslation();

    return(
        <div country={UtilsService.getLocaleFromUrl().country}>
            <div className="cd-404-page">
                <div className="cd-max-width">
                    <div className="cd-404-wrapper">
                        <div className="cd-404-content">
                            <div className="cd-404-info">
                                <h1>{t('miniCart.emptyCart')}</h1>
                                <img src={breakImg}  alt="404 Icon" />
                            </div>
                            <Link className="button" to={`/${UtilsService.getLocaleFromUrl().country}/shop/`}>{t('miniCart.continueShopping')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartEmpty