// Dependency
import React from "react";

// Components
import { useCart } from "../../contexts/cart-context";
import Loader from "../loader/loader-overlay";

// Template
export default function OrderRewiev({ children, checkedLocked }) {

    const { isLoading } = useCart();

    return (
        <div className="box-white">
            <Loader active={isLoading} />
            {children}
            {checkedLocked && <div style={{ zIndex: 99, position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, .5)" }}></div>}

        </div>
    )
}
