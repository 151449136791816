//Dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";

//Components
//import { useCart } from "../../../contexts/cart-context";
import UtilsService from "../../../services/utils.service";
import Storage from "../../../services/localStorage.service";

const Qliro = ({ location, setCheckedLocked, country }) => {
    //const { cart } = useCart();
    const [form, setForm] = useState();
    const selection = Storage.getWithExpiry('selection');
    useEffect(() => {
        if (selection) axios.defaults.headers.post['Accept'] = `*/*;api-token: ${selection.token}`;
        axios({
            method: 'post',
            url: `${process.env.CENTRA_URL}/payment`,
            data: {
                "paymentMethod": "qliro",
                "paymentReturnPage": `${location.origin}/${country}/thankyou/`,
                "paymentFailedPage": `${location.origin}/${country}/checkout/`,
                "termsAndConditions": "true"
                // "additionalFields": {
                //   "mailchimp_campaign_id": "7897179",
                //   "mailchimp_landing_site": "https://silly-swartz-7a46f3.netlify.app/se"
                // }
            }
        }).then(response => {
            setForm(response.data);

            window.totalPrice = selection && selection.selection.totals.grandTotalPriceAsNumber

        }).catch((error) => {
            console.log(error)
        });
    }, [])

    useEffect(() => {
        if (form && form.formHtml) {
            const checkoutContainer = document.getElementById('checkoutContainer'); // Reference to element already on page
            checkoutContainer.innerHTML = form.formHtml;
            const scriptsTags = checkoutContainer.getElementsByTagName("script")
            const newTags = []
            for (let i = 0; i < scriptsTags.length; i++) {
                const newScriptTag = document.createElement("script")
                if (scriptsTags[i].text) {
                    newScriptTag.type = "text/javascript"
                    newScriptTag.text = scriptsTags[i].text.replace(/\\\"/gi, "'");
                    newScriptTag.id = 'TESTdds'
                } else {
                    newScriptTag.async = true
                    newScriptTag.src = scriptsTags[i].src
                    newScriptTag.id = 'TEST'
                }

                newTags.push(newScriptTag)
            }

            checkoutContainer.innerHTML = ''
            const qd = document.createElement("div")
            qd.id = 'qliro-root'
            checkoutContainer.appendChild(qd)
            newTags.forEach((tag) => {
                checkoutContainer.appendChild(tag)
            })
            UtilsService.evaluate(checkoutContainer);

            window.q1Ready = function (q1) {
                q1.onPaymentProcess(function onStart() {
                    // Lock the interface to prevent the customer from changing the order
                    setCheckedLocked(true)
                }, function onEnd() {
                    // Unlock the interface to open up for order changes again
                    setCheckedLocked(false)
                })

                q1.onShippingMethodChanged(function callback(shipping) {
                    // console.log(shipping)
                    // For example: Apply discounts based on shipping method and options

                })

                q1.onOrderUpdated(function (order) {
                    // console.log(order)
                    // console.log(window.totalPrice)
                    // if order reflects the local cart, use q1.unlock()
                    if (order.totalPrice === window.totalPrice) {
                        // exampel of how the order can be verified
                        q1.unlock()
                    }
                    // else, don't do anything
                })
            }
        }

    }, [form])

    return (
        <div id="checkoutContainer"></div>
    )
}

export default Qliro