// Dependency
import React, { useEffect, useState } from "react";
import axios from "axios";

// Contexts
import NotificationContext from "../../components/errors/notification-context";

// Services
import GA from "../../services/ga.service";

// Components
import Layout from "../../components/layouts";
import CartList from "../../components/mini-cart/cart-list/cart-list";
// import CartBanner from "../../components/mini-cart/cart-banner/cart-banner";
import CartSummary from "../../components/mini-cart/cart-summary/cart-summary";
import CartEmpty from "../../components/mini-cart/cart-empty/cart-empty";
//import { useCart } from "../../contexts/cart-context";
import Storage from "../../services/localStorage.service";
import Ingrid from "../../components/checkout/ingrid/ingrid";
import PaymentContainer from "../../components/checkout/payment-container";


// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as checkoutStyles from "./checkout.module.scss"
import OrderRewiev from "../../components/checkout/order-review";
import CheckoutCart from "../../components/checkout/checkout-cart";
import Upsell from "../../components/mini-cart/cart-suggest/upsell";

// Template
const CheckoutPage = ({ location, pageContext }) => {
    const storedCart = Storage.getWithExpiry('selection');
    //const storedCartItems = storedCart ? storedCart.selection.items : [];
    const [cartItems, setCartItems] = useState(false);
    const [checkedLocked, setCheckedLocked] = useState(false);
    const [upsellProds, setUpsellProds] = useState([]);

    useEffect(() => {
        //Todo check if country change and delay ajax until right lang is set
        if (storedCart.token && storedCart.selection.items.length > 0) {
            const cartIds = [];
            storedCart.selection.items.map((item) => {
                cartIds.push(item.product.product)
            })
            axios.defaults.headers['post']['Accept'] = `*/*;api-token: ${storedCart.token}`
            axios({
                method: 'post',
                url: `${process.env.CENTRA_URL_PRODUCTS}`,
                data: { products: cartIds, relatedProducts: true }
            }).then(response => {

                let products = [];
                response.data.products.map((product) => {
                    product.relatedProducts.map((relProd) => {
                        if (!cartIds.includes(relProd.product)) products.push(relProd);
                    })
                })

                setUpsellProds(products);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [])

    // Translation Function
    const { t } = useTranslation();

    const seoData = { tags: [{ tagName: "title", content: "Checkout | Flowlife" }] }

    // GA.onCheckout(cartItems);

    return (
        <Layout country={pageContext.country} isCheckout={true} seoData={seoData} location={location}>
            <CheckoutCart setCart={setCartItems} />
            {cartItems && cartItems.length === 0 ? <CartEmpty /> :
                <div className={checkoutStyles.cdCheckoutPage}>
                    <div className={`${checkoutStyles.cdCheckoutWrapper} cd-max-width`} >
                        <div className="layout--checkout">
                            <div className="content">
                                <div className="box-white">
                                    <div style={{ position: 'relative' }}>
                                        <Ingrid country={pageContext.country} />
                                        {checkedLocked && <div style={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, .8)" }}></div>}
                                    </div>
                                    <PaymentContainer country={pageContext.country} location={location} setCheckedLocked={setCheckedLocked} />
                                </div>
                            </div>
                            <div className="sidebar">
                                <OrderRewiev checkedLocked={checkedLocked}>
                                    <h4 className="sidebar__title">{t('checkout.orderSum')}</h4>
                                    <CartList hasBorder={true} />
                                    <div className="cd-checkout-upsell">
                                        {/*Upsell*/}
                                        {upsellProds.length > 0 ? <Upsell upsell={upsellProds} country={pageContext.country} /> : ''}
                                    </div>

                                    <NotificationContext.Consumer>
                                        {(setContentModalContent) => <CartSummary displayNotification={setContentModalContent} />}
                                    </NotificationContext.Consumer>
                                </OrderRewiev>

                            </div>
                        </div>
                    </div>
                </div>}
        </Layout>
    )
}

export default CheckoutPage;