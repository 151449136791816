// Dependency
import React from "react";

//Components
import Adyen from "./adyen/adyen";
//import Klarna from "./klarna/klarna";
import Qliro from "./qliro/qliro";

// Template
const PaymentContainer = ({ country, location, setCheckedLocked }) => {

    return (
        <Qliro country={country} location={location} setCheckedLocked={setCheckedLocked} />
    )
}

export default PaymentContainer
