// Dependency
import React from 'react';

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from './vouchers.module.scss';

// Template
const Vouchers = ({ isOpen, voucher, removeVoucher, setVoucher, submitVoucher, cart }) => {
  // Translation Function
  const { t } = useTranslation();

  return (
    <div className={`${styles.vouchers} ${isOpen ? styles.isOpen : ''}`}>
      <form onSubmit={(e) => { e.preventDefault(); submitVoucher({ "voucher": voucher }, cart.token) }}>
        <input
          type="text"
          value={voucher}
          placeholder={t('checkout.yourCoupon')}
          onChange={(event) => { setVoucher(`${event.target.value}`) }}
        />
        <button className='button' type="button" onClick={() => { submitVoucher({ "voucher": voucher }, cart.token) }}>{t('checkout.apply')}</button>
      </form>
      <div>
        {
          cart && cart.selection.discounts.vouchers && cart.selection.discounts.vouchers.map( (voucher) => {
            return <div className={styles.cdVoucherRemove} key={voucher.voucher}>
              <pre>{voucher.description}</pre>
              <button onClick={() => {removeVoucher(voucher.voucher, cart.token)}}>[ {t('checkout.remove')} ]</button>
            </div>
          })
        }
      </div>
    </div>
  );
};

export default Vouchers;