// Dependency
import React, { useState, useEffect } from 'react';
import { useCart } from '../../../contexts/cart-context';

// Services
import VoucersService from '../../../services/vouchers.service';

// Components
//import CartBanner from '../cart-banner/cart-banner';
import Vouchers from '../../vouchers/vouchers';

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from './cart-summary.module.scss';

// Template
const CartSummary = ({ displayNotification }) => {
  const { cart, setCart } = useCart();

  // Voucher visibility state
  const [vaucherOpen, setVaucherOpen] = useState(false);
  // Voucher value state
  const [voucherValue, setVoucherValue] = useState('');

  // Extracted "totals" field
  const totals = cart ? cart.selection.totals : '';
  // Extracted "currency" field
  const currency = cart ? cart.selection.currency : '';
  // Extracting "shippingMethods" field
  const shipping = cart ? cart.shippingMethods[0] : '';

  const { t } = useTranslation();

  // Submit voucher
  const submitVoucher = (voucerCode, token) => {
    if (window.CentraCheckout) window.CentraCheckout.suspend();
    // Using service
    VoucersService.submit(voucerCode, token)
      .then(response => {
        // Show notification on successfull voucher apply
        if (response.status === 201) {
          displayNotification.toggleNotifications({
            type: 'success',
            copy: {
              title: 'Voucher',
              text: t('voucher.success')
            }
          }, 3000);
          // and update cart object
          setCart(response.data);
          if (window.CentraCheckout) { window.CentraCheckout.resume(); }
        }
      })
      .catch(error => {
        if (window.CentraCheckout) { window.CentraCheckout.resume(); }
        displayNotification.toggleNotifications({
          type: 'warning',
          copy: {
            title: 'Ouch!',
            text: t('voucher.error')
          }
        }, 3000);
      });
  };

  // Submit voucher
  const removeVoucher = (voucerCode, token) => {
    if (window.CentraCheckout) window.CentraCheckout.suspend();
    // Using service
    VoucersService.remove(voucerCode, token).then(response => {
      // Show notification on successfull voucher apply
      if (response.status === 200) {
        displayNotification.toggleNotifications({
          type: 'success',
          copy: {
            title: 'Voucher',
            text: t('voucher.removed')
          }
        }, 3000);
        // and uodate cart object
        setCart(response.data);
        if (window.CentraCheckout) { window.CentraCheckout.resume(); }
      }
    })
      .catch(error => {
        if (window.CentraCheckout) { window.CentraCheckout.resume(); }
        displayNotification.toggleNotifications({
          type: 'warning',
          copy: {
            title: 'Ouch!',
            text: t('voucher.error')
          }
        }, 3000);
      });
  };

  const [discountTotal, setDiscountTotal] = useState(0)


  useEffect(() => {
    let discount = 0
    cart.selection.items.map((item, i) => {
      discount += (item.priceEachReductionAsNumber * item.quantity)
    })
    setDiscountTotal(discount)

  }, [cart])


  return (
    <React.Fragment>
      <div className={styles.cdCartCoupon}>
        <h4 className="sidebar__title">
          {t('checkout.coupon')}
          <button onClick={() => { setVaucherOpen(!vaucherOpen) }}>{t('checkout.addCoupon')}</button>
        </h4>
      </div>
      <Vouchers
        isOpen={vaucherOpen}
        voucher={voucherValue}
        setVoucher={setVoucherValue}
        submitVoucher={submitVoucher}
        removeVoucher={removeVoucher}
        cart={cart}
      />
      <div className={`${styles.cartSummary}`}>
        <p>
          <span>{t('checkout.priceBeforeDiscount')}</span>
          <strong>{totals.itemsTotalPrice}</strong>
        </p>
        {totals.totalDiscountPrice ?
          <p>
            <span>{t('checkout.discount')}</span>
            <strong className={styles.sale}>{totals.totalDiscountPrice ? `${(totals.totalDiscountPriceAsNumber - discountTotal)} ${currency}` : `0 ${currency}`}</strong>
          </p>
          : discountTotal !== 0 ?
            <p>
              <span>{t('checkout.discount')}</span>
              <strong className={styles.sale}>{`-${discountTotal} ${currency}`}</strong>
            </p>
            :

            ''}
        <p>
          <span>{t('marquee.free')} {t('marquee.shipping')}</span>
          <strong>{totals && totals.shippingPrice}</strong>
        </p>
        <p>
          <span>{t('checkout.tax')}</span>
          <strong>{totals && totals.grandTotalPriceTax}</strong>
        </p>
        <p className={`${styles.cartSummary__total}`}>
          <strong>{t('checkout.total')}</strong>
          {cart ? <strong>{totals.grandTotalPrice}</strong> : ''}
        </p>
      </div>
    </React.Fragment>
  );
};

export default CartSummary;